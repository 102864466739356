@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$my-primary: mat.define-palette(
  (
    50: #e0e3e9,
    100: #b3b8c7,
    200: #808ba1,
    300: #4d5e7b,
    400: #26375f,
    500: #1c2a4b,
    600: #192545,
    700: #141d3b,
    800: #101631,
    900: #0a0d22,
    A100: #7d8eff,
    A200: #4a5eff,
    A400: #1730ff,
    A700: #0011ff,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);

$my-accent: mat.define-palette(
  (
    50: #e1f0ff,
    100: #b3d9ff,
    200: #80bfff,
    300: #4da5ff,
    400: #268fff,
    500: #448aff,
    600: #1c78ff,
    700: #1b69ff,
    800: #165aff,
    900: #0c3eff,
    A100: #ffffff,
    A200: #e6f3ff,
    A400: #b3d1ff,
    A700: #99bfff,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);

$my-warn: mat.define-palette(
  (
    50: #f9e3e2,
    100: #f1b9b6,
    200: #e88b86,
    300: #df5d56,
    400: #d93a32,
    500: #dd332b,
    600: #c72e26,
    700: #af2720,
    800: #981f1a,
    900: #711411,
    A100: #ffb7b5,
    A200: #ff8a87,
    A400: #ff5d59,
    A700: #ff433f,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $dark-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "'Open Sans'",
      ),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);
@include mat.form-field-density(-4);

*,
*::before,
*::after {
  letter-spacing: normal !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    Open Sans,
    sans-serif;
  background-color: #ecf0f5;
  letter-spacing: normal !important;
}

html {
  --mat-toolbar-container-background-color: #424242;
  --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-toolbar-standard-height: 50px;
  --mat-sidenav-content-background-color: tranparent !important;
  --mdc-protected-button-container-elevation-shadow: none;
  --mdc-filled-button-label-text-weight: 600;

  // TABLE
  --mat-table-header-container-height: 40px;
  --mat-table-row-item-container-height: 48px;
  --mat-table-row-item-outline-color: #f3f3f3;

  // MODAL
  --mdc-dialog-supporting-text-color: #000;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-color: #1c2a4b;

  // BASIC BUTTON

  // FAB SMALL BUTTON

  .mat-mdc-standard-chip {
    --mdc-chip-container-shape-radius: 4px !important;
    --mdc-chip-label-text-weight: 700;
    --mdc-chip-container-height: 20px !important;
    --mdc-chip-label-text-size: 11px !important;
    --mdc-chip-elevated-container-color: rgba(28, 42, 75, 0.04);
    --mdc-chip-label-text-color: rgba(28, 42, 75, 1);
  }

  .mdc-fab--mini {
    width: 30px;
    height: 30px;
    --mdc-fab-small-container-shape: 4px;
    --mdc-fab-small-container-elevation-shadow: none;
    --mdc-fab-small-focus-container-elevation-shadow: none;
    --mdc-fab-small-hover-container-elevation-shadow: none;
  }

  .mdc-fab--mini.mat-basic {
    border: 1px solid #cbd5e1 !important;
    --mat-fab-small-foreground-color: #1c2a4b;
    --mdc-fab-small-container-color: #eef1f7;
  }

  button.mat-basic {
    border: 1px solid #cbd5e1 !important;
    --mdc-filled-button-label-text-color: #1c2a4b;
    --mdc-filled-button-container-color: #eef1f7;
  }

  div.home-search {
    max-height: 500px !important;
  }

  .mat-button-toggle-button,
  .mat-button-toggle-appearance-standard,
  mat-button-toggle-group.mat-button-toggle-group.items-toggle-button-container,
  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: rgba(238, 241, 247, 1);
    border-radius: 5px;
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked button {
    background-color: rgba(28, 42, 75, 1);
    color: #fff;
  }

  mat-button-toggle-group.mat-button-toggle-group.items-toggle-button-container {
    background-color: rgba(238, 241, 247, 1) !important;
  }

  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle-appearance-standard
    + .mat-button-toggle-appearance-standard {
    border-left: 0;
  }
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-supporting-text-color: #51a351;
  }

  &.red-snackbar {
    --mdc-snackbar-supporting-text-color: #ff4a43;
  }
}

mat-card {
  border: 1px solid #cbd5e1 !important;
  box-shadow: none !important;
}

table thead tr {
  background-color: #f5f7fa !important;
  font-weight: 600 !important;
  color: #515e76 !important;
}

table tbody tr:nth-child(even) {
  background-color: #fafafa !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.v-middle {
  vertical-align: middle;
}

.v-sub {
  vertical-align: sub;
}

mat-chip.green {
  background-color: #f1f9f0 !important;
  --mdc-chip-label-text-color: #22962d !important;
}

mat-chip.red {
  background-color: #fff1f0 !important;
  --mdc-chip-label-text-color: #ff726b !important;
}

mat-chip.blue {
  background-color: #ecf3ff !important;
  --mdc-chip-label-text-color: #165aff !important;
}

.mat-mdc-dialog-title {
  border-bottom: 1px solid #cbd5e1 !important;
}

.mat-mdc-dialog-content {
  mat-form-field {
    width: 100%;

    .dialog-section mat-icon {
      vertical-align: middle !important;
    }
  }
}

.bulk-dialog-field {
  display: flex;

  mat-checkbox {
    padding-right: 5px;
  }
}

.mat-mdc-tab {
  background-color: #fff !important;
  font-weight: 600 !important;
}

.mat-mdc-progress-bar.app-progress-bar {
  z-index: 9;
  background-color: #fff !important;
}

span.dialog-section mat-icon {
  vertical-align: middle;
}

.w-100 {
  width: 100% !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.r-0 {
  right: 0 !important;
}

.r-7 {
  right: 7px !important;
}

.f-16 {
  font-size: 16px !important;
  line-height: 19px !important;
}

.f-12 {
  font-size: 12px !important;
  line-height: 15px !important;
}

.h-100 {
  height: 100% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.text-grey {
  color: grey !important;
}

.mat-icon.s-icon {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
}

.cursor-pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.subtitle {
  font-size: 14px;
  color: #656671;
  padding-left: 4px;
}

.avatar-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(28, 42, 75, 1);
  color: rgba(28, 42, 75, 1);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
}

.unassigned-icon {
  scale: 0.8;
  display: flex !important;
  justify-content: center;
}

.white-bg {
  background-color: white;
  --bs-gutter-x: 0;
}

.dropdown-error {
  color: #dd332b;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 16px;
  position: relative;
  width: 100%;
}

.custom-mat-error {
  font-size: 12px;
  padding-left: 1rem;
}

.space-btw {
  display: flex;
  justify-content: space-between;
}

.grey-text {
  color: rgba(0, 0, 0, 0.74);
}

.md-drppicker .ranges ul li button.active,
.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker .btn:not(.btn.btn-default) {
  background-color: #448aff !important;
  opacity: 1 !important;
}

a.link-without-underline {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.horizontal-scroll-card-list {
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 4rem;
}

.flex-remaining-space {
  flex: 1;
  min-width: 0;
}

.flex-static-space {
  flex: 0 0 auto;
}
